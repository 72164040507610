@import '../config';

section {
	padding: $section-gutter 0;
	@include max940px {
		padding: 4rem 0;
	}

	@include max480px {
		padding: 2rem 0;
	}
}

.content {
	.content__copy {
		max-width: 800px;
		width: 100%;
		margin: 0 auto;
		
		h3, h4, p, ul li {
			text-align: left;
		}

		p {
			margin-bottom: 1.5rem;
		}
	}

	.content__block {
		margin-bottom: 4.5rem;
		h3 {
			margin-bottom: 2rem;
		}
	}

	.content__block--aBlock {
		p {
			text-align: justify;
		}
	}

	.content__note {
		text-align: center;
	}
}

.wrapper {
	max-width: $grid-max-width;
	width: 100%;
	margin: 0 auto;
	padding: 0 1rem;
	box-sizing: border-box;
}

table {
	border-collapse: collapse;
}
