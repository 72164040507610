@import '../config';
@import '../core/breakpoints';

html {
	font-family: $font-poppins;
	font-size: $base-font-size;
	line-height: $base-line-height;
	color: $dark-blue;
	font-weight: 300;
}

h1, h2, h3, h4, h5 {
	line-height: 1.35;
	margin-bottom: 0.5em;
}

h1 {
	font-size: $h1-font-size;
	margin-bottom: 2.25rem;
	@media screen and (max-width: 1260px) {
		font-size: 3rem;
	}

	@include max640px {
		font-size: 2.25rem;
		margin-bottom: 1.5rem;
	}
}

h2 {
	font-size: $h2-font-size;
	text-align: center;
	margin-bottom: 4rem;
	@include max940px {
		font-size: 2rem;
		margin-bottom: 3rem;
	}

	@include max480px {
		margin-bottom: 1.5rem;
	}
}

h3 {
	font-size: $h3-font-size;
	text-align: center;
	margin-bottom: 3rem;
	@include max940px {
		font-size: 1.85rem;
		margin-bottom: 2rem;
	}
}

h4 {
	font-size: $h4-font-size;
	text-align: center;
	margin-bottom: 2rem;
	@include max940px {
		font-size: 1.5rem;
	}
}

h5 {
	font-size: $h5-font-size;
}

p {
	font-size: $base-paragraph-size;
	font-weight: 300;
	line-height: 2;
	text-align: center;
	margin-bottom: 3rem;
}

.perex {
	margin-bottom: 5rem;
	font-size: 1.25rem;
	font-weight: 500;
	color: $gray;
}

.explanation {
	font-size: 0.9rem;
	font-style: italic;
}

strong {
	font-weight: 700;
}

a {
	color: inherit;
	@if ($underlined-links == true) {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	} @else {
		text-decoration: none;
	}
}

.list {
	padding-left: 0;
	li {
		background-image: url("#{$images-path}/ui/indent.svg");
		background-repeat: no-repeat;
		background-position: 0 6px;
		list-style: none;
		padding-left: 1.5rem;
		margin-bottom: 0.75rem;
	}
}

br {
	@include max768px {
		display: none;
	}
}
