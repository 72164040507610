// Grid size
$grid-max-width: 1140px;


// Spaces
$section-gutter: 6rem;


// Typography
$base-font-size: 15px;
$base-line-height: 1.5;

$base-paragraph-size: 1rem;

$h1-font-size: 3.5rem;
$h2-font-size: 2.75rem;
$h3-font-size: 2.25rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.25rem;

$underlined-links: true;


// Fonts
$font-poppins: 'Poppins', sans-serif;

// Images
$images-path: "../../images";


// Colors
$white: #FFF;
$black: #000;
$gray: #616161;

$dark-blue: #091837;
$light-blue: #0A8EEB;
$lightest-blue: #F7FCFF;

$base-text-color: $black;


// Forms
$form-columns-gap: 1rem;

$form-input-padding-tops: 0.5rem;
$form-input-padding-sides: 1rem;

$form-input-border-radius: 0.25em;
$form-input-border-width: 1px;
$form-input-border-color: #AEAEB2;

$form-input-text-size: $base-font-size;

$form-input-background-color: $white;
$form-input-text-color: $dark-blue;


// Common radius
$base-radius: 0;
$button-radius: 0;

// Header
$header-bg-color: $white;


// Hamburger
$hamburger-breakpoint: 940px;

$hamburger-label-color: $white;
$hamburger-line-color: $white;

$hamburger-overlay-color: $light-blue;
$hamburger-overlay-item-color: $white;

$hamburger-line-width: 35px;
$hamburger-line-height: 2px;
$hamburger-line-radius: 2.5px;
$hamburger-line-gap: 6px;

$hamburger-height: calc(($hamburger-line-height * 3) + $hamburger-line-gap * 2);
